import React, {useEffect, useState} from 'react'
import { Button, Col, Row, Table, DatePicker, Select, Form, Input, Checkbox, Switch } from 'antd'
import { 
    UserOutlined, 
} from '@ant-design/icons'
import Column from 'antd/lib/table/Column'
import Moment from 'moment'
import 'moment/locale/es'
import locale from 'antd/es/date-picker/locale/ca_ES'
import {useDispatch, useSelector} from "react-redux"
import {
    CrearDataAdministrarUsuariosReducer,
    ActualizarDataAdministrarUsuariosReducer,
    CambiarEstadoUsuariosReducer,
} from '../../../Redux/Actions/Administrar/Usuarios/Usuarios'

const TablaAcciones = (props) => {
    const form = props.form
    const vaciarFormulario = props.vaciarFormulario
    const activoSwitch = props.activoSwitch
    const setActivoSwitch = props.setActivoSwitch
    const activoSwitchAzure = props.activoSwitchAzure
    const setActivoSwitchAzure = props.setActivoSwitchAzure
    const idEditarUsuario = props.idEditarUsuario
    const setIdEditarUsuario = props.setIdEditarUsuario
    const numeroPagina = props.numeroPagina
    const txtBuscar = props.txtBuscar

    const { Option } = Select
    const dispatch = useDispatch()

    const {
        rex_data_tipo_usuarios,
        rex_estado_boton_usuarios,
        rex_estado_desabilitar_usuarios,
        rex_estado_checkbox_usuarios,
        rex_estado_fecha_usuarios,
        rex_estado_contrasenia_usuarios,
    } = useSelector(({administrarUsuarios}) => administrarUsuarios)

    const {
        rex_data_tabla_paises,
    } = useSelector(({administrarPaises}) => administrarPaises)

    const [cargandoBotonFormulario, setCargandoBotonFormulario] = useState(false)
    const [fechaFinActualizado, setFechaFinActualizado] = useState(false)

    const buscarFecha = (fechaBuscar) => {
        const fechabloquear = fechaBuscar ? Moment(fechaBuscar._d).format('YYYY-MM-DD') : ""
        dispatch(CambiarEstadoUsuariosReducer(null, null, null, fechabloquear, null))
        fechabloquear > Moment(fechaFinActualizado).format('YYYY-MM-DD') 
                        && form.setFieldsValue({fechaFin: ''})
    }

    const desabilitarFecha = (current) => {
        return current && current < Moment(rex_estado_fecha_usuarios) 
    }

    const habilitarCorreo = (contrasenia) => {
        const contraseniavalue = contrasenia.target.value;
        if(idEditarUsuario){
            if (contraseniavalue) {
                dispatch(CambiarEstadoUsuariosReducer(null, null, false, null, null))
            }else{
                dispatch(CambiarEstadoUsuariosReducer(null, null, true, null, null))
            }
        }else{
            dispatch(CambiarEstadoUsuariosReducer(null, null, false, null, null))
        }
    }

    const habilitarEdicion = () => {
        dispatch(CambiarEstadoUsuariosReducer(true, false, null, null, null))
    }

    const enviarFormulario = async (values) => {
        setCargandoBotonFormulario(true)
        if(idEditarUsuario){
            let respuesta = await dispatch(ActualizarDataAdministrarUsuariosReducer(
                idEditarUsuario,
                values.nombre, 
                values.apellido, 
                values.correoCorporativo,
                values.correoPersonal, 
                values.contrasenia,
                values.celular,
                values.tipoUsuario,
                Moment(values.fechaInicio._d).format('YYYY-MM-DD HH:mm:ss'),
                Moment(values.fechaFin._d).format('YYYY-MM-DD HH:mm:ss'),
                values.pais,
                activoSwitch,
                activoSwitchAzure,
                values.correoBienvenida,
                numeroPagina, 
                txtBuscar,
            ))
            setCargandoBotonFormulario(false)
            if(respuesta){
                vaciarFormulario()
                dispatch(CambiarEstadoUsuariosReducer(null, false, false, null, true))
                setIdEditarUsuario()
            }
        }else{
            let respuesta = await dispatch(CrearDataAdministrarUsuariosReducer(
                values.nombre, 
                values.apellido, 
                values.correoCorporativo,
                values.correoPersonal, 
                values.contrasenia,
                values.celular,
                values.tipoUsuario,
                Moment(values.fechaInicio._d).format('YYYY-MM-DD HH:mm:ss'),
                Moment(values.fechaFin._d).format('YYYY-MM-DD HH:mm:ss'),
                values.pais,
                activoSwitch,
                activoSwitchAzure,
                values.correoBienvenida,
                numeroPagina, 
                txtBuscar
            ))
            setCargandoBotonFormulario(false)
            if(respuesta){
                vaciarFormulario()
                dispatch(CambiarEstadoUsuariosReducer(null, false, false, null, true))
            }
        }
    }

    const dataSource = [{}]
    
    const columns = () => (
        <Row>
            <Form
                form={form}
                style={{
                    display:'flex',
                    width: '100%',
                    flexWrap: 'wrap'
                }}
                initialValues={{
                    nombre: '',
                    apellido: '',
                    correoPersonal: '',
                    correoCorporativo: '',
                    contrasenia: '',
                    celular: '',
                    tipoUsuario: '',
                    fechaInicio: '',
                    fechaFin: '',
                    pais: '',
                    estado: true,
                    azure: false,
                    correoBienvenida: false,
                }}
                autoComplete="off"
                onFinish={enviarFormulario}
                onValuesChange={false}
            >
                <Col md={12} sm={24} className="formAdministrarUsuariosRight">
                    <Form.Item 
                        labelCol={{
                            span: 9,
                        }}
                        labelAlign="left"
                        wrapperCol={{
                            span: 15,
                        }} 
                        name="nombre"
                        className="form-labelAdministrarUsuario"
                        label="Nombre"
                        rules={[
                            {
                              required: true,
                              message: 'Por favor ingrese un nombre',
                            },
                        ]}
                    >
                        <Input 
                            disabled={rex_estado_desabilitar_usuarios} 
                        />
                    </Form.Item>
                    <Form.Item 
                        labelCol={{
                            span: 9,
                        }}
                        labelAlign="left"
                        wrapperCol={{
                            span: 15,
                        }} 
                        name="apellido"
                        className="form-labelAdministrarUsuario"
                        label="Apellidos"
                        rules={[
                            {
                              required: true,
                              message: 'Por favor ingrese un apellido',
                            },
                        ]}
                    >
                        <Input 
                            disabled={rex_estado_desabilitar_usuarios} 
                        />
                    </Form.Item>
                    <Form.Item 
                        labelCol={{
                            span: 9,
                        }}
                        labelAlign="left"
                        wrapperCol={{
                            span: 15,
                        }}
                        name="correoCorporativo"
                        className="form-labelAdministrarUsuario"
                        label="Correo Corporativo"
                        rules={[
                            {
                              required: true,
                              message: 'Por favor ingrese un correo corporativo.',
                            },
                            {
                                type: 'email',
                                message: 'Ingrese un correo válido.',
                            }
                        ]}
                    >
                        <Input 
                            disabled={rex_estado_desabilitar_usuarios} 
                        />
                    </Form.Item>
                    <Form.Item 
                        labelCol={{
                            span: 9,
                        }}
                        labelAlign="left"
                        wrapperCol={{
                            span: 15,
                        }} 
                        name="correoPersonal"
                        className="form-labelAdministrarUsuario"
                        label="Correo Personal"
                        rules={[
                            {
                                type: 'email',
                                message: 'Por favor ingrese un correo válido.',
                            }
                        ]}
                    >
                        <Input 
                            disabled={rex_estado_desabilitar_usuarios} 
                        />
                    </Form.Item>
                    <Form.Item 
                        labelCol={{
                            span: 9,
                        }}
                        labelAlign="left"
                        wrapperCol={{
                            span: 15,
                        }} 
                        name="contrasenia"
                        className="form-labelAdministrarUsuario"
                        label="Contraseña"
                        rules={[
                            {
                              required: rex_estado_contrasenia_usuarios,
                              message: 'Por favor ingrese una contraseña',
                            },
                        ]}
                    >
                        <Input.Password 
                            disabled={rex_estado_desabilitar_usuarios} 
                            className="contraAdministrarUsuario"
                            onChange={habilitarCorreo}
                        />
                    </Form.Item>
                    <Form.Item 
                        labelCol={{
                            span: 9,
                        }}
                        labelAlign="left"
                        wrapperCol={{
                            span: 15,
                        }} 
                        name="celular"
                        className="form-labelAdministrarUsuario"
                        label="Celular"
                    >
                        <Input 
                            disabled={rex_estado_desabilitar_usuarios} 
                        />
                    </Form.Item>
                </Col>
                <Col md={12} sm={24} className="formAdministrarUsuariosLeft">
                    <Form.Item 
                        labelCol={{
                            span: 9,
                        }}
                        labelAlign="left"
                        wrapperCol={{
                            span: 15,
                        }} 
                        rules={[
                            {
                              required: true,
                              message: 'Por favor ingrese un tipo de usuario',
                            },
                        ]}
                        className="form-labelAdministrarUsuario"
                        label="Tipo de Usuario"
                        name="tipoUsuario"
                    >
                        <Select
                            className="selectAdministrarArchivo"
                            defaultValue="Seleccione un tipo usuario"
                            disabled={rex_estado_desabilitar_usuarios}
                        >
                            <Option value="">Seleccione un tipo usuario</Option>
                            {
                                rex_data_tipo_usuarios.map((data) => (
                                    <Option 
                                        key={data.tpuid} 
                                        value={data.tpuid}
                                    >
                                        {data.tpunombre}
                                    </Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item 
                        labelCol={{
                            span: 9,
                        }}
                        labelAlign="left"
                        wrapperCol={{
                            span: 15,
                        }}
                        name="fechaInicio"
                        className="form-labelAdministrarUsuario"
                        label="Fecha Inicio"
                    >
                        <DatePicker 
                            disabled={rex_estado_desabilitar_usuarios} 
                            size="large" 
                            placeholder="Fecha Inicio"
                            className="fechaAdministrarUsuarios" 
                            locale={locale} 
                            onChange={buscarFecha}
                        />
                    </Form.Item>
                    <Form.Item 
                        labelCol={{
                            span: 9,
                        }}
                        labelAlign="left"
                        wrapperCol={{
                            span: 15,
                        }}
                        name="fechaFin"
                        className="form-labelAdministrarUsuario"
                        label="Fecha Fin"
                    >
                        <DatePicker
                            locale={locale} 
                            size="large" 
                            placeholder="Fecha Fin"
                            disabled={rex_estado_desabilitar_usuarios} 
                            className="fechaAdministrarUsuarios" 
                            onChange={(e) => setFechaFinActualizado(e)}
                            disabledDate={desabilitarFecha}
                        />
                    </Form.Item>
                    <Form.Item 
                        labelCol={{
                            span: 9,
                        }}
                        labelAlign="left"
                        wrapperCol={{
                            span: 15,
                        }}
                        rules={[
                            {
                              required: true,
                              message: 'Por favor ingrese un pais',
                            },
                        ]}
                        name="pais"
                        className="form-labelAdministrarUsuario"
                        label="País"
                    >
                        <Select 
                            showSearch
                            className="selectAdministrarArchivo" 
                            disabled={rex_estado_desabilitar_usuarios} defaultValue="Seleccione un pais"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.title.toLowerCase().includes(input.toLocaleLowerCase())
                            }
                        >
                            <Option value="" title="">Seleccione un pais</Option>
                            {
                                rex_data_tabla_paises.map((p, index) => (
                                    <Option key={index} value={p.paiid} title={p.painombre}>
                                        <img 
                                            src={p.paiicono} 
                                            className="selectAdministrarArchivoImagen"
                                        />
                                        {p.painombre}
                                    </Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item 
                        labelCol={{
                            span: 9,
                        }}
                        labelAlign="left"
                        wrapperCol={{
                            span: 15,
                        }}
                        className="form-labelAdministrarUsuario"
                        label="Estado"
                    >
                        <div className={`form-containerSwitchAdministrarUsuario ${rex_estado_desabilitar_usuarios ? 'disabledColor' : ''}`}>
                            <p style={{ marginBottom:'0' }}>{activoSwitch === 1 ? 'Activo' : 'Inactivo'}</p>
                            <Form.Item 
                                wrapperCol={{
                                    span: 24,
                                }}
                                style={{
                                    marginBottom: '0'
                                }}
                                name="estado"
                                className="form-labelAdministrarUsuario"
                                rules={[
                                    {
                                        required: true,
                                    }
                                ]}
                            >
                                <Switch 
                                    disabled={rex_estado_desabilitar_usuarios}
                                    className="form-switchAdministrarUsuario"
                                    checked={activoSwitch === 1 ? true : false}
                                    onChange={(checked) => {
                                        checked ? setActivoSwitch(1) : setActivoSwitch(2)
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Form.Item>
                    {/* <Form.Item 
                        labelCol={{
                            span: 9,
                        }}
                        labelAlign="left"
                        wrapperCol={{
                            span: 15,
                        }}
                        className="form-labelAdministrarUsuario"
                        label="Azure"
                    >
                        <div className={`form-containerSwitchAdministrarUsuario ${rex_estado_desabilitar_usuarios ? 'disabledColor' : ''}`}>
                            <p style={{ marginBottom:'0' }}>{activoSwitchAzure === 1 ? 'Activo' : 'Inactivo'}</p>
                            <Form.Item 
                                wrapperCol={{
                                    span: 24,
                                }}
                                style={{
                                    marginBottom: '0'
                                }}
                                name="azure"
                                className="form-labelAdministrarUsuario"
                                rules={[
                                    {
                                        required: true,
                                    }
                                ]}
                            >
                                <Switch 
                                    disabled={rex_estado_desabilitar_usuarios}
                                    className="form-switchAdministrarUsuario"
                                    checked={activoSwitchAzure === 1 ? true : false}
                                    onChange={(checked) => {
                                        checked ? setActivoSwitchAzure(1) : setActivoSwitchAzure(0)
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </Form.Item> */}
                </Col>
                <Col md={12} sm={24} className="formAdministrarUsuariosRight" style={{width:'100%'}}>
                    <Form.Item 
                        labelCol={{
                            span: 0,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            textAlign:'start'
                        }}
                        name="correoBienvenida"
                        valuePropName="checked"
                    >
                        <Checkbox 
                            disabled={rex_estado_checkbox_usuarios}
                        >
                            Enviar correo de Bienvenida
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col md={12} sm={24} className="formAdministrarUsuariosLeft" style={{width: '100%'}}>
                    <Form.Item 
                        labelCol={{
                            span: 0,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            textAlign:'end'
                        }}
                    > 
                        <Button 
                            type="primary" 
                            htmlType="submit" 
                            shape="round"
                            className={`${rex_estado_boton_usuarios ? '' : 'btnFormularioOcultar'}`}
                            loading={cargandoBotonFormulario}
                        >
                            Guardar
                        </Button>
                        <Button 
                            onClick={() => habilitarEdicion()}
                            type="primary" 
                            shape="round"
                            className={`${rex_estado_boton_usuarios ? 'btnFormularioOcultar' : ''}`}
                        >
                            Editar
                        </Button>
                    </Form.Item>
                </Col>
            </Form>
        </Row>
    )

    return(
        <Table 
            className="table-administrarUsuarios"
            dataSource={dataSource} 
            pagination={false}
        >
            <Column 
                title={(
                    <div
                        className="table-formularioAdministrarUsuario"
                    >
                        <UserOutlined style={{ fontSize: '20px' }} />
                        <p>Usuario</p>
                    </div>
                )}
                className="table-thformulario"
                dataIndex="form" 
                key="form" 
                render={columns} 
            />
        </Table>
    )
}

export default TablaAcciones