import config from '../../../config'
import configMicroServices from '../../../configMicroServices'
import React from 'react'
import { estadoRequestReducer } from "../EstadoRequest"
import {OBTENER_NOTIFICACIONES_CARGA_ARCHIVOS} from '../../../Constantes/CargaArchivos/CargaArchivos'
import axios from 'axios'
import {message} from "antd"
import moment from 'moment'
import { notification } from "antd"
import { FECHA_ACTUALIZACION_SO } from '../../../Constantes/SubsidiosSo/SubsidiosSo'

export const CargarArchivoReducer = (url, data, file) => async(dispatch, getState) => {

    let respuesta = false
    let respuesta_servidor = false

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'multipart/form-data',
    }

    if(config.produccion == true){
        headerFetch = {
            'Accept' : 'application/json',
            'content-type': 'multipart/form-data',
            'api_token': localStorage.getItem('usutoken'),
            'api-token': localStorage.getItem('usutoken'),
        }
    }

    await axios.post(url, data,{
        mode:'cors',
        headers: headerFetch
    })
    .then(rpta => {
        let datos = rpta.data
        if(datos.respuesta == true){
            respuesta = true
        }else{
            message.error(datos.mensaje);
            console.log(rpta)
            if(rpta.data?.errorArchivo){
                respuesta_servidor = true
            }
        }
        dispatch(ObtenerNotificacionesReducer(datos.logs))

    })
    .catch((error)=> {
        respuesta_servidor = true
        console.log(error)
    });

    if(url == config.api+"modulo/cargaArchivos/si/sell-in-thanos"){
        await dispatch(SellInHomologadosMicroServicesReducer(file))
    }

    return {
        "todo_bien" : respuesta,
        "error_axios" : respuesta_servidor
    }
}

export const ObtenerFechaActualizacionSellOutReducer = () => async(dispatch, getState) => {

    let respuesta = false
    let respuesta_servidor = false

    const url = configMicroServices.api_js + 'extractor/update-date-so'

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
    }

    if(config.produccion == true){
        headerFetch = {
            'Accept' : 'application/json',
            'content-type': 'application/json',
            'api_token': localStorage.getItem('usutoken'),
            'api-token': localStorage.getItem('usutoken'),
        }
    }

    await axios.post(url, {},{
        mode:'cors',
        headers: headerFetch
    })
    .then(rpta => {
        let datos = rpta.data
        if(datos.response == true){
            dispatch({
                type : FECHA_ACTUALIZACION_SO,
                payload : datos.data.created_at
            })
        }else{
            notification.warning({
                message: 'Notificación',
                description: datos.message,
                placement : 'topRight',
            });
        }
    })
    .catch((error)=> {
        respuesta_servidor = true
        console.log(error)
    });

    return {
        "todo_bien" : respuesta,
        "error_axios" : respuesta_servidor
    }
}

export const CargarSellOutReducer = () => async(dispatch, getState) => {

    const LoginUsuario = getState().login.LoginUsuario

    const dataUser = {
        'usuid' : LoginUsuario.usuid,
        'usuusuario' : LoginUsuario.usuusuario,
    }

    let respuesta = false
    let respuesta_servidor = false

    const url = configMicroServices.api_js + 'extractor/get-so'

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
    }

    if(config.produccion == true){
        headerFetch = {
            'Accept' : 'application/json',
            'content-type': 'application/json',
            'api_token': localStorage.getItem('usutoken'),
            'api-token': localStorage.getItem('usutoken'),
        }
    }

    await axios.post(url, dataUser,{
        mode:'cors',
        headers: headerFetch
    })
    .then(rpta => {
        let datos = rpta.data
        if(datos.response == true){
            notification.success({
                message: 'Notificación',
                description: datos.message,
                placement : 'topRight',
            })
        }else{
            notification.warning({
                message: 'Notificación',
                description: datos.message,
                placement : 'topRight',
            })
        }
    })
    .catch((error)=> {
        respuesta_servidor = true
        console.log(error)
    });

    return {
        "todo_bien" : respuesta,
        "error_axios" : respuesta_servidor
    }
}

export const ObtenerNotificacionesReducer = (notificacion) => async (dispatch, getState) => {

    

    let notificaciones_cargaarchivos = getState().cargaArchivos.notificaciones_cargaarchivos
    let numeroNoti = notificaciones_cargaarchivos.length+1
    notificacion["TITULO"] = "Notificación "+numeroNoti


    notificaciones_cargaarchivos.unshift(notificacion)

    dispatch({
        type: OBTENER_NOTIFICACIONES_CARGA_ARCHIVOS,
        payload : notificaciones_cargaarchivos
    })

}

export const EliminarNotificacionReducer = (posicion) => (dispatch, getState) => {
    
    let notificaciones_cargaarchivos = getState().cargaArchivos.notificaciones_cargaarchivos
    notificaciones_cargaarchivos.splice(posicion,1)
    dispatch({
        type: OBTENER_NOTIFICACIONES_CARGA_ARCHIVOS,
        payload : notificaciones_cargaarchivos
    })
    
}

export const SellInHomologadosMicroServicesReducer = (fileData) => async (dispatch, getState) => {
    const formData = new FormData()
    const { LoginUsuario } = getState().login
    let ruta_back = ""

    if(LoginUsuario.usuid == 1){
        ruta_back = 'carga-archivos/upload-sellin'
        formData.append('carga_sellin', fileData)
        formData.append('req_date', moment().format("Y-m-d"))
    }else{
        formData.append('file_s3', fileData)
        formData.append('req_date', null)
        ruta_back = 'carga-archivos/guardar-s3'
    }

    formData.append('req_usucorreo', LoginUsuario.usuusuario)
    formData.append('req_espbasedato', "Sell In Thanos")
    formData.append('req_plataforma', "Subsidios")
    formData.append('req_type_file', "Sell In Thanos")

    await axios.post(configMicroServices.api_url+ruta_back, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'usutoken'	  : '',
        },
    })
}