import React, {useState, useRef, useEffect} from 'react'
import config from '../../config'
import IconoMariposaColor from '../../Assets/Imagenes/Iconos/Login/Flying-money.gif'
import {Link, useLocation} from "react-router-dom"
import NuevoLogov2 from '../../Assets/Imagenes/Login/Logo_new_2.png'
import '../../Estilos/Login/IntroLogin.css'
import IconoGoogle from '../../Assets/Imagenes/Login/Iconos/google.png'
import IconoAzure from '../../Assets/Imagenes/Login/Iconos/azureicono.png'
import IconoGrow from '../../Assets/Imagenes/Login/Iconos/growiconoblanco.png'
import IconoCargandoChat from '../../Assets/Imagenes/Login/Iconos/cargandochat.gif'
import { notification } from "antd"
import ChatWithUs from '../../Componentes/Elementos/Chat/ChatWithUs'
import LoginGifIntro from '../../Assets/Gifs/Login/logointro.gif'
import { useDispatch } from 'react-redux'
import { LoginAzureRedirectReducer } from '../../Redux/Actions'

const IntroLogin = () => {

    const [cargandoLogin, setCargandoLogin] = useState(false)
    const [txtUsuario, setTxtUsuario] = useState("")
    const [txtContrasenia, setTxtContrasenia] = useState("")
    const [mostrarGif, setMostrarGif] = useState(true)

    const { pathname } = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        setTimeout(() => {
            setMostrarGif(false)
        }, '6000');
    }, [])

    // const loginAzureRedirect = async (token_user) => {
    //     await dispatch(LoginAzureRedirectReducer(token_user))
    // }

    // useEffect(() => {
    //     const separate_pathname = (pathname).split("/")
    //     if(separate_pathname[1] == "login" && separate_pathname[2]){
    //         loginAzureRedirect(separate_pathname[2])
    //     }
    // }, [])

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                textAlign: "-webkit-center",
                placeContent: "center",
                position:'relative'
            }}
        >
            <div className="iconoLoginEfecto1">
                <img 
                    src={IconoMariposaColor} 
                    className="Icono-Mariposa"
                />
            </div>
            
            <div className="iconoLoginEfecto2">
                <img 
                    src={IconoMariposaColor} 
                    className="Icono-Mariposa"
                />
            </div>

            <div className="iconoLoginEfecto3">
                <img 
                    src={IconoMariposaColor} 
                    className="Icono-Mariposa"
                />
            </div>

            <div className="iconoLoginEfecto4">
                <img 
                    src={IconoMariposaColor} 
                    className="Icono-Mariposa"
                />
            </div>

            <div className="iconoLoginEfecto5">
                <img 
                    src={IconoMariposaColor} 
                    className="Icono-Mariposa"
                />
            </div>

            <div className="iconoLoginEfecto6">
                <img 
                    src={IconoMariposaColor} 
                    className="Icono-Mariposa"
                />
            </div>

            <div className="iconoLoginEfecto7">
                <img 
                    src={IconoMariposaColor} 
                    className="Icono-Mariposa"
                />
            </div>

            <div>
                
                <div style={{position:'relative'}}>
                    <img 
                        src={NuevoLogov2} 
                        className="Icono-Subsidios-Login-Nuevo"
                    />
                </div>
                
                <Link to="/login-formulario">
                    <div className='Btn-Inicio-Grow'>
                        <img src={IconoGrow} className="Icono-Grow-IntroLogin" />
                        <div>
                            Iniciar con Grow
                        </div>
                    </div>
                </Link>

                <div 
                    className='Btn-Inicio-Azure'
                    onClick={() => {
                        notification.info({
                            message: `Notificación`,
                            description: "Lo sentimos, esta opción no esta habilitada",
                            placement: 'topRight',
                        })
                        // window.location.href = config.api+"login-azure"
                    }}
                >
                    <img src={IconoAzure} className="Icono-Azure-IntroLogin" />
                    <div>
                        Iniciar con Azure
                    </div>
                </div>

                <div 
                    className='Btn-Inicio-Google'
                    onClick={() => {
                        notification.info({
                            message: `Notificación`,
                            description: "Lo sentimos, esta opción no esta habilitada",
                            placement: 'topRight',
                        })
                    }}
                >
                    <img src={IconoGoogle} className="Icono-Azure-IntroLogin" style={{top:'0'}} />
                    <div>
                        Iniciar con Google
                    </div>
                </div>

                <div
                    className='txt-opaco-intrologin'
                >
                    © 2022 GROW ANALYTICS
                </div>                

            </div>
            
            <ChatWithUs styles={{bottom: '60px'}} />
            
            {
                mostrarGif == true
                ?<div id="Contenedor-Gif-Intro-Login">
                    <img id="Gif-Intro-Login" src={LoginGifIntro} />
                </div>
                :null
            }
        </div>
    )
}

export default IntroLogin